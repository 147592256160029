<template>
    <div class="container border-container">
        <div class="search-container">
            <span class="list-title">学生列表</span>
            <el-input v-if="false" class="circular-bead-input" placeholder="请输入学生名称" prefix-icon="el-icon-search" title="按回车键搜索"
                @keyup.enter.native="pageChange(1)" @clear="pageChange(1)" v-model='search.name' >
            </el-input>
        </div>
        <div class="table-container">
            <el-table :data="schoolStudentList" tooltip-effect="dark" style="width: 100%" size="medium"
                :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                :cell-style="{height: '50px',padding: '5px 0'}" header-align="center">
                <el-table-column type="selection" width="55" align="center" v-if="userType === USER_TYPE.SCHOOL"></el-table-column>
                <el-table-column label="学生名称" min-width="120" :show-overflow-tooltip="true"  align="center" >
                    <template slot-scope="scope">
                        <div class="fz-bold">
                            {{scope.row.name}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="生日" min-width="125" align="center">
                    <template slot-scope="scope">
                        {{scope.row.birthday | timeFilter(2)}}
                    </template>
                </el-table-column>
                <el-table-column label="性别" min-width="125" align="center">
                    <template slot-scope="scope">
                        {{scope.row.gender | genderFilter}}
                    </template>
                </el-table-column>
                <el-table-column label="操作" min-width="100" align="center" v-if="auth('SCHOOL')">
                    <template slot-scope="scope">
                        <el-button type="text" class="icon-btn" @click="handleDeleteStudent(scope.row.id)">
                            <el-tooltip placement="top" content="删除学生">
                                <i class="iconfont icon-delete"></i>
                            </el-tooltip>
                        </el-button>
                    </template>
                </el-table-column>
                <div class="" slot="empty">
                    <no-data></no-data>
                </div>
            </el-table>
        </div>
        <div class="page-container">
            <el-pagination layout="total, sizes, prev, pager, next" background
                @size-change="pageSizeChange"
                @current-change="pageChange"
                :current-page="currentPage"
                :page-sizes="[total, 10, 15, 20, 25]"
                :total="total" :page-size="pageSize">
            </el-pagination>
        </div>
        <el-dialog class="school-student-dialog qr-code"
            title="查看学生信息"
            :visible.sync="dialogVisible"
            :before-close="handleDialogClose">
            <div class="school-student-dialog-content">
                <img :src="imgSrc" alt="" />
                <p v-if="imgSrc">微信扫码查看学生详细信息</p>
            </div>
            <span slot="footer" class="dialog-footer" style="display:flex;justify-content: center;">
                <el-button @click="handleDialogClose">取 消</el-button>
                <el-button type="primary" @click="handleDialogClose">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Core from 'core';

export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {
        orgId: {
            type: [Number,String]
        },
        schoolId: {
            type: [Number,String]
        },
        classId: {
            type: [Number,String],
            default: 0
        }
    },
    data() {
        return {
            total: 0,
            currentPage: 1,
            pageSize: 10,
            dialogVisible: false,
            schoolStudentList: [],
            classList: [],
            studentEdit:{
                id: '',
                name: '',
                classId: ''
            },
            imgSrc: '',
            userType: Core.Data.getUserType(),
            USER_TYPE: Core.Const.USER_TYPE,
        };
    },
    created() {
        this.getSchoolStudentList();
    },
    filters: {
        genderFilter(val) {
            let gender = '';
            switch (val) {
                case 0:
                    gender = '女'
                    break;
                case 1:
                    gender = '男'
                    break;
                default:
                    break;
            }
            return gender
        }
    },
    methods: {
        auth: Core.Utils.auth,
        pageChange(page) { // 页面改变
            this.currentPage = page;
            this.getSchoolStudentList();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getSchoolStudentList();
        },
        getSchoolStudentList() { // 获取学生列表
            Core.Api.Student.list(
                this.currentPage,
                this.pageSize,
                this.orgId,
                this.schoolId || 0,
                this.classId,
                0,
            ).then((res) => {
                this.total = res.count
                this.schoolStudentList = res.list
            });
        },
        handleDeleteStudent(id) { // 删除 学生
            this.$confirm('确定要删除这名学生吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Core.Api.Student.delete(id).then((res) => {
                    this.$message.success('删除成功!');
                    this.pageChange(1)
                })
            })
        },
        handleDialogShow(id) { // 显示弹框 二维码图片信息
            if (id) {
                this.studentEdit.id = id
                let token = Core.Data.getToken()
                this.imgSrc = `https://api.course.school.rebuildsoft.com/school/1/student/detail-qrcode?token=${token}&id=${id}`
            }
            this.dialogVisible = true
        },
        handleDialogClose() { // 关闭弹框
            this.dialogVisible = false
        },
    }
};
</script>
<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
    .search-container {
        @include flex(row, space-between, center);
    }
    .school-student-dialog.qr-code{
        p{
            text-align: center;
            font-size: 20px;
            margin-top: 16px;
        }
    }
}
</style>


<style lang="scss">
.school-student-dialog.qr-code{
    .el-dialog{
        width: 400px;
        border-radius: 5px;
        .el-dialog__body{
            padding-top: 0;
        }
    }
}
</style>